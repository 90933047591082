import React from "react"
import PropTypes from "prop-types"
import "./photo-album-gallery.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Masonry from "react-masonry-css"
import { getAltText, addCloudinaryAttributionFlag } from "../../helpers/helpers"

function PhotoAlbumGallery({ data }) {
    const images = data.allCloudinaryMedia.edges

    function getAlbumPage(url) {
        return url.toLowerCase().replace(/\s/g, "")
    }

    return (
        <Masonry
            breakpointCols={{
                default: 4,
                1200: 3,
                900: 2,
                500: 1,
            }}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
        >
            {images.map((image, index) => (
                <div key={`${index}-cl`} className="album">
                    <a
                        href={getAlbumPage(
                            image.node.context.custom.album_thumbnail,
                        )}
                        className="album__photo"
                        tabIndex={index + 3}
                    >
                        <LazyLoadImage
                            alt={getAltText(image.node.context)}
                            src={addCloudinaryAttributionFlag(
                                image.node.secure_url,
                            )}
                            className="image"
                            effect="opacity"
                            wrapperClassName="photo-list__item"
                        />
                        <div className="container">
                            <p className="link album__link">
                                {image.node.context.custom.album_thumbnail}
                            </p>
                        </div>
                    </a>
                </div>
            ))}
        </Masonry>
    )
}

PhotoAlbumGallery.propTypes = {
    data: PropTypes.object.isRequired,
}

export default PhotoAlbumGallery
