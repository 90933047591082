import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PhotoAlbumGallery from "../components/photo-album-gallery/photo-album-gallery.js"
import { GlobalHead } from "../helpers/helpers"

const Images = () => {
    const data = useStaticQuery(graphql`
        query CloudinaryAlbumsGallery {
            allCloudinaryMedia(
                filter: {
                    public_id: { regex: "/^rm-photos?//" }
                    context: { custom: { album_thumbnail: { ne: null } } }
                }
                sort: { context: { custom: { album_thumbnail: ASC } } }
            ) {
                edges {
                    node {
                        public_id
                        secure_url
                        context {
                            custom {
                                alt
                                album_thumbnail
                            }
                        }
                    }
                }
            }
        }
    `)

    return <PhotoAlbumGallery data={data} />
}

export function Head() {
    return <GlobalHead></GlobalHead>
}

export default function Gallery() {
    return <Layout>{Images()}</Layout>
}
